.nav-item button.nav-link {
  color: #20368b !important;
}
.mui-ypiqx9-MuiDialogContent-root {
  padding: 4px 4px !important;
}
.wiocc-btn {
  background: #2846bb !important;
}

.wiocc-btn:hover {
  background: #20368b !important;
}

.nav-item button.nav-link:hover {
  color: black !important;
}

.nav-item button.nav-link.active {
  background-color: white;
  color: black !important;
  font-weight: bold;
}
.MuiBox-root .mui-1spqycp {
  width: 100% !important;
  overflow-x: auto;
}

form .invalid-feedback {
  font-size: 110%;
  font-weight: 500;
}

.MuiToolbar-root.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p {
  margin: auto;
}

table.MuiTable-root td {
  /* font-size: 14px !important; */
  /* font-weight: 100 !important; */
  /* font-family: "Montserrat-Regular"; */
  font-family: "Montserrat", sans-serif;
}

form label:not(.form-check-label) {
  font-family: "Montserrat-Medium";
  font-size: 120%;
}

form .invalid-feedback {
  font-size: 120%;
}

body {
  font-family: "Montserrat";
  font-size: 0.7rem;
  font-weight: 500;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
}

body h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

table.MuiTable-root td div {
  font-size: 14px !important;
}

.input-group .form-check .form-check-input {
  margin-top: auto !important;
}

.react-toast-notifications__container.css-nvf14r-ToastContainer {
  z-index: 1030;
}

.MuiTableCell-root.MuiTableCell-body.css-1ex1afd-MuiTableCell-root {
  padding: 0.4em;
}

table
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-1dccma1-MUIDataTablePagination-toolbar.MuiTablePagination-toolbar.mui-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

table
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-1dccma1-MUIDataTablePagination-toolbar.MuiTablePagination-toolbar.mui-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  p {
  margin-bottom: 0 !important;
}

.gray-bg {
  background: linear-gradient(to right, #b0b1b3, #e2efff, #b0b1b3, #e2efff);
}

.home-bg {
  background-image: url(../images/network.jpg);
  background-repeat: no-repeat;
}

.home-bg .mainBodyDiv {
  background: none;
}

.regular-text {
  /* font-family: "Montserrat-Regular"; */
  font-size: 14px !important;
}

input[type="file"].form-control.is-invalid {
  display: block;
  border: 1px solid white !important;
}

.custom-mui-datatable .MuiTable-root tbody tr td {
  padding: 0.75em 10px !important;
}

.custom-mui-datatable-sm .MuiTable-root tbody tr td {
  padding: 0.45em 10px !important;
}

.mui-table-title {
  display: flex;
  justify-content: space-between;
}

.mui-table-title h6 {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}

.MuiTableCell-root.MuiTableCell-head.tss-10syd3x-MUIDataTableHeadCell-root.css-1ygcj2i-MuiTableCell-root {
  padding: 0.3em 10px;
}

.MuiTableCell-root.MuiTableCell-head.tss-10syd3x-MUIDataTableHeadCell-root.css-1ygcj2i-MuiTableCell-root
  button {
  padding: 0.2em 16px;
}

.tss-1akey0g-MUIDataTableHeadCell-data {
  text-transform: uppercase;
  font-weight: bold;
}

.w-15 {
  width: 15%;
}

.w-35 {
  width: 35%;
}
